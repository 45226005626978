import React, { useState } from "react"
import axios from 'axios'
import { Link } from "gatsby"
import Layout from "../../../components/layout"
// import { Helmet } from 'react-helmet'
import SEO from "../../../components/seo"
import Img from "../../../components/image-transform"

const CustomerService = () => {
  const [onSubmit, setOnSubmit] = useState({message: "Download now", isSubmitted: false });
  const [emailAdd, hasEmail] = useState('')
  const [name, hasName] = useState('')
  const [company, hasCompany] = useState('')
  const [error, isError] = useState()
  const [disabled, isDisabled] = useState(true)

  const handleChange = event => {
    hasEmail(event.target.value)
    isDisabled(false)
    setOnSubmit({message: "Download now", isSubmitted: false });
  }

  const handleChangeName = eventi => {
    hasName(eventi.target.value)
    isDisabled(false)
    setOnSubmit({message: "Download now", isSubmitted: false });
  }
  const handleChangeCompany = eventj => {
    hasCompany(eventj.target.value)
    isDisabled(false)
    setOnSubmit({message: "Download now", isSubmitted: false });
  }
  
  const handleSubmit = event => {
    event.preventDefault();

    const pattern = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const result = pattern.test(emailAdd);

    if (result === true) {
      isError(false)
      isDisabled(true)
      setOnSubmit({message: "Sending...", isSubmitted: false });

      axios({
        method: 'post',
        url: `https://cors-anywhere.herokuapp.com/https://api.mailerlite.com/api/v2/groups/89953448/subscribers`,
        headers: { 'content-type': 'application/json', 'X-MailerLite-ApiKey': 'f13b6f484a31a41bbdefdd972b1d6099'  },
        data: {
          email: emailAdd,
          name: name,
          type: 'active',
          fields: {
            company: company
          }
        }
      }).then(res=> {
          setOnSubmit({message: "Thanks! We've emailed your eBook to you.", isSubmitted: true });
          hasEmail('');
          hasName('');
          hasCompany('');
      }).catch((error) => {
        setOnSubmit({message: "Ops! Please try again.", isSubmitted: false });
      })
    } else (
      isError(true)
    )
  };
  return (
    <Layout>
      <SEO 
        title="Deliver Great Customer Service to Subscription Customers | Free eBook" 
        description="Deliver great customer service and increase the porfitability of your subscription business with our free eBook - download it today to learn our top service tips." 
        url="https://www.billsby.com/resources/guides/delivering-great-customer-service-to-subscription-customers"
      />

      <div className="ebook">
        <div className="section-hero">
          <div className="container container-flex">
            <div className="section-content">
              <h1 className="section-heading">Delivering great customer service to subscription customers.</h1>
              <p className="section-text">There is no denying that customer service lies at the core of any business today. According to research provided by Walker, by 2020, customer experience is going to be more important than both product and price. Furthermore, 70 per cent of the journey the customer goes on is dictated by how they feel they are being treated. Plus, if that was not enough to convince you that customer service is imperative, Gartner have also revealed that 89 per cent of companies today compete through the level of customer experience they are able to provide. </p>
              <p className="section-text">In this eBook, we reveal everything that you need to know about how to deliver great customer service to subscription customers specifically.</p>

              <a className="btn-orange" href="#ebookForm">Download free eBook</a>
            </div>
            <div className="section-image">
              <Img filename="ebook-service.png" className="main-img" alt="Delivering great customer service to subscription customers" />
            </div>
          </div>
        </div>
      
        <div className="section-ebook">
          <div className="container container-flex">
            <div className="ebook-content">
              <h2 className="section-heading">What the differences are between providing service to customers in a transactional business and a subscription business?</h2>
              <p className="section-text">Before we delve into the different customer service approaches for subscription-based businesses, it is important to understand how they differ from transactional businesses in this regard. With transactional companies, great emphasis is placed on acquiring customers and guiding them through the buying process. Of course, aftercare is critical to ensure repeat business. However, with a subscription business, levels of customer service need to remain consistent, month-on-month, to ensure value is repeatedly being delivered so that customers do not cancel their subscriptions. Needless to say, you’re never going to be able to prevent each and everyone of your customers from unsubscribing.</p>
              <p className="section-text">However, customer service still becomes incredibly critical at this stage, as your customer service agents are responsible for ensuring that data is extracted in terms of why that person left, as well as attempting to make them reconsider their decision or sign-up again in the future.</p>
              <p className="section-text">The customer journey is an incredibly different one when it comes to subscription-based businesses, and this is why customer service must be assessed from a different angle too.</p>            
            </div>
            <div className="ebook-image">
              <img src={require('../../../images/customer-journey.svg')} alt="customer-journey"/>
            </div>
          </div>
        </div>
        <div className="section-ebook">
          <div className="container container-flex">
            <div className="ebook-content">
              <h2 className="section-heading">How to provide your customers with exceptional service as a subscription-based business</h2>
              <p className="section-text">Now that you have a good understanding regarding the different ways in which a subscription-based model differs from a transactional business in terms of customer experience, let’s take a look at some of the different approaches and techniques that you can use in order to enhance the customer service you provide as a subscription business.</p>
              <p className="section-title">Reward loyalty</p>
              <p className="section-text">There is only one place to begin, and this is with rewarding loyalty. There are a lot of brands nowadays that make the most of this approach, and this is because it is effective. There are many different ways that you can reward loyalty. For example, you can give customers discounts when they reach certain milestones, for example, if you run a subscription box service, you may decide to give customers 20 per cent off their box every six months. Another option is to surprise customers with more valuable items in their boxes every so often. Make sure these surprises get more and more frequent the longer that the person is subscribed. This adds real value to the person’s experience and it makes sure that they keep subscribing. There are plenty of other perks that you consider offering depending on the nature of your subscription basis, for example, you may want to offer free training for your product.</p>
              <p className="section-title">Make sure friction is reasonable when it comes to the cancellation journey</p>
              <p className="section-text">No one wants someone to unsubscribe from their product. However, this is natural when it comes to subscription services. People are not going to remain customers forever. The way in which you handle the cancellation journey is going to say a lot about your business. While it can be tempting to make it as difficult as possible for people to leave, you’re not going to be doing your company any favours. All this is going to do is lead to negative comments being posted about your company online, which can stunt your growth and prevent people from signing up in the future. This does not mean that there cannot be any friction at all when it comes to cancellations. It is all about reasonable friction. It is all about friction that is actually going to benefit your business while not causing any irritation or anger</p>            
            </div>
            <div className="ebook-image">
              <img src={require('../../../images/customer-service.svg')} alt="customer-service"/>
            </div>
          </div>
        </div>
        <div id="ebookForm" className="ebook-form">
          <div className="container">
            <div className="form-wrapper container-flex">
              <form>
                <h2 className="section-heading">Download our free eBook today to learn the rest of our ways to deliver great customer service</h2>

                <div className="form-group">
                  <input type="text" className={`input-field ${error ? 'error' : ''}`} placeholder="Enter you name" name="name" value={name} onChange={handleChangeName} />
                  
                  <input type="email" className={`input-field ${error ? 'error' : ''}`} placeholder="Enter your email address" name="emailAdd" value={emailAdd} onChange={handleChange}/>

                  <input type="text" className={`input-field ${error ? 'error' : ''}`} placeholder="Enter your company name" name="companyName" value={company} onChange={handleChangeCompany}/>

                  <button type="submit" className={ `btn-blue ${onSubmit.isSubmitted ? 'btn-submitted' : ''}` } onClick={ handleSubmit } disabled={disabled}>{ onSubmit.message }</button>

                  <p className="section-text">You'll be signed up for marketing communications from Billsby. We respect your <Link to="/privacy">privacy</Link>.</p>
                </div>

              </form>
              <div className="brochure-holder">
               <Img filename="ebook-service.png" className="main-img" alt="Delivering great customer service to subscription customers" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default CustomerService